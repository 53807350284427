import * as React from "react";
import { useEffect, useState } from "react";

import { Helmet } from "react-helmet";
import { type } from "os";
import { Page } from "../components/Page";
import Container from "../components/Container";
import DefaultLayout from "../layouts/default";
import {
  EventApi,
  EventAttributes,
  TicketPaymentDataAttributesResultEnum,
} from "../api";
import { defaultAPIConfig } from "../config/defaultApiConfig";
import ContentWrapper from "../components/Wrappers/ContentWrapper";
import SectionTitle from "../components/Text/SectionTitle";
import { InhaltElem } from "../components/Text/SmallerInhalt";

interface ReservationSuccessTemplateProps {
  pageContext: {
    attributes: EventAttributes;
    id: string;
  };
}

const api = new EventApi(defaultAPIConfig());

const ReservationSuccessTemplate: React.FC<ReservationSuccessTemplateProps> = (
  props
) => {
  // All realtime data (ticket availability, ..)
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const referenceId = params.get("referenceId");
    const eventId = params.get("eventId");

    const tellBackend = async () => {
      const payload = {
        ticketPayment: {
          data: {
            type: "ticketpayment",
            attributes: {
              result: TicketPaymentDataAttributesResultEnum.Successful,
              eventId,
              referenceId,
            },
          },
        },
      };
      try {
        const response = await api.ticketpaymentPost(payload, {
          headers: {
            accept: "application/vnd.api+json",
            "content-type": "application/vnd.api+json",
          },
        });
        // console.log("success", response);
      } catch (error) {
        // console.log("There was an error: ", error);
      }
    };

    tellBackend();
  }, []);

  return (
    <DefaultLayout>
      <Helmet>
        <title>Successfully booked! - STEP</title>
      </Helmet>
      <Page>
        <Container>
          <ContentWrapper>
            <br />
            <br />
            <br />
            <InhaltElem>
              <h1>Successfully booked</h1>
              <br />
              <p>You will receive an email with further details.</p>
            </InhaltElem>
          </ContentWrapper>
        </Container>
      </Page>
    </DefaultLayout>
  );
};

export default ReservationSuccessTemplate;
